<template>
  <div class="popup_wrap doc_req_pop">
    <button class="layer_close" @click="$emit('close')">close</button>
    <form
      id="frm_req_pop"
      @submit.prevent
    >
      <div class="popup_cont shuttle_cont_area">
        <!-- popup_cont -->
        <h1 class="page_title">
          <!-- 컨테이너 셔틀 요청서 -->
          {{ $t('msg.ONEX020P130.001') }}
          <span class="position_relative screen-to-pdf-hide">
            <button class="tbl_icon help" @mouseover="showToolTip()" @mouseleave="showToolTip()">{{ $t('msg.ONEX020P130.057') }}</button>
            <div v-show="toolTipActive" class="tooltip_wrap short position_absolute" style="width:400px; left:0; top:28px;"><!-- tooltip_wrap   -->
              <div class="cont"><!-- cont -->
                <p class="title">{{ $t('msg.ONEX020P130.001') }}</p><!-- 컨테이너 셔틀 요청서 -->
                <ul class="bul_list_sm t2">
                  <li>
                    {{ $t('msg.ONEX020P130.002') }} <br /> {{ $t('msg.ONEX020P130.067') }}
                  </li>
                </ul>

                <table class="tbl_col mt10" style="width:100%;">
                  <colgroup>
                    <col width="20%">
                    <col width="30%">
                    <col width="25%">
                    <col width="25%">
                  </colgroup>
                  <thead>
                    <tr>
                      <th rowspan="2">{{ $t('msg.ONEX020P130.003') }}</th>
                      <th rowspan="2">{{ $t('msg.ONEX020P130.004') }}</th>
                      <th colspan="2">{{ $t('msg.ONEX020P130.005') }}</th>
                    </tr>
                    <tr>
                      <th class="border_left">{{ $t('msg.ONEX020P130.006') }}</th>
                      <th>{{ $t('msg.ONEX020P130.007') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan="2">{{ $t('msg.ONEX020P130.008') }}</td>
                      <td>{{ $t('msg.ONEX020P130.009') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.010') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.011') }}</td>
                    </tr>
                    <tr>
                      <td class="border_left">{{ $t('msg.ONEX020P130.014') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.012') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.013') }}</td>
                    </tr>
                    <tr>
                      <td rowspan="2">{{ $t('msg.ONEX020P130.015') }}</td>
                      <td>{{ $t('msg.ONEX020P130.016') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.010') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.011') }}</td>
                    </tr>
                    <tr>
                      <td class="border_left">{{ $t('msg.ONEX020P130.017') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.012') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.013') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('msg.ONEX020P130.018') }}</td>
                      <td>{{ $t('msg.ONEX020P130.016') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.010') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.011') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('msg.ONEX020P130.019') }}</td>
                      <td>{{ $t('msg.ONEX020P130.016') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.010') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.011') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('msg.ONEX020P130.020') }}</td>
                      <td>{{ $t('msg.ONEX020P130.016') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.010') }}</td>
                      <td class="text_right">&#8361;{{ $t('msg.ONEX020P130.011') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div><!-- cont -->
            </div><!-- tooltip_wrap // -->
          </span>
        </h1>
        <div
          v-if="delegateYn === 'Y'"
          class="content_box"
        >
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX020P130.021') }}</th><!-- 날짜 -->
                <td>
                  <div class="tbl_form">
                    {{ $ekmtcCommon.changeDatePattern(nowDate, '.') }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX020P130.022') }}</th><!-- 수신 -->
                <td>{{ $t('msg.ONEX020P130.023') }}</td><!-- KOREA MARINE TRANSPORT CO., LTD -->
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX020P130.024') }}</th><!-- 발신 -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.shprCstNm }}
                  </div>
                  <div class="tbl_form mt5">
                    <div>
                      <span class="dv col_1">{{ formData.reqPicNm }}</span>
                      <span class="dv col_2">{{ formData.reqPicTelNo }}</span>
                      <span class="dv col_3">{{ formData.reqPicEmlAddr }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX020P130.025') }}</th><!-- 실화주명(B/L Shipper) -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.actShprCstEnm }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- content_box // -->

        <div
          v-if="delegateYn === 'Y'"
          class="content_box mt10 shuttle_cont_area"
        >
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX020P130.028') }}</th><!-- Booking No. -->
                <td>
                  <div class="tbl_form">
                    {{ bookingInfo.blNo }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX020P130.029') }}</th><!-- Port of Loading/Discharge -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_3">
                      {{ bookingInfo.polPlcNm }}
                    </span>
                    <span class="dv col_3">
                      {{ bookingInfo.podPlcNm }}
                    </span>
                    <span class="dv col_4">

                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX020P130.030') }}</th><!-- Vessel -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_3">
                      {{ bookingInfo.vslNm }}
                    </span>
                    <span class="dv col_3">
                      {{ bookingInfo.voyNo }}
                    </span>
                    <span class="dv col_4">

                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="tbl_row mt10">
            <colgroup>
              <col width="200"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>
                  {{ $t('msg.ONEX020P130.033') }} {{ $t('msg.ONEX020P130.034') }}<br /> <!-- 셔틀 컨테이너 & 요청 구간 -->
                  <p class="screen-to-pdf-hide">
                    <button type="button" class="button blue lg mt10" @click.prevent="setCntrInfoAll()">{{ $t('msg.ONEX020P130.031') }}</button> <!-- 모두 선택 -->
                  </p>
                </th>
                <td>
                  <div
                    class="tbl_form div_cntr_area screen-to-pdf-hide"
                    v-for="(vo, idx) in formData.containerList"
                    :key="'cntrList_' + idx"
                    :id="'alertArea_'+ idx"
                  >
                    <span class="dv col_4">
                      <select
                        v-model="vo.cntrNo"
                        :id="'cntrNo_' + idx"
                        @focus="isPrevValue($event)"
                        @change="[setCntrInfo(idx), fnChkCntrNo(idx)]"
                      >
                        <option value="">{{ $t('msg.ONEX020P130.074') }}</option><!-- 선택 -->
                        <option
                          v-for="option in formData.cntrNoList"
                          :key="option.cntrNo"
                          :value="option.cntrNo"
                        >
                          {{ option.cntrNo }}
                        </option>
                      </select>
                    </span>
                    <span class="dv">
                      <button
                        v-if="idx === 0"
                        class="tbl_icon plus"
                        @click="addContainer()"
                      >
                        plus
                      </button>
                      <button
                        v-if="idx !== 0"
                        class="tbl_icon minus"
                        @click="removeContainer(idx)"
                      >
                        minus
                      </button>
                    </span>
                    <span class="dv col_3">
                      <input type="text" :id="'fromTerminal_' + idx" v-model="vo.bfTrmlNm" placeholder="From - Terminal" readonly>
                    </span>
                    <span class="dv col_3">
                      <input type="text" :id="'toTerminal_' + idx" v-model="vo.updtTrmlNm" placeholder="To - Terminal" readonly>
                    </span>
                  </div>
                  <div
                    class="tbl_form div_cntr_area screen-to-pdf-show"
                    v-for="(vo, idx) in formData.containerList"
                    :key="'cntr_' + idx"
                    :id="'alertArea_'+ idx"
                  >
                    <p>
                      <span class="dv col_3">{{ vo.cntrNo }}</span>
                      <span class="dv col_3">{{ vo.bfTrmlNm }}</span>
                      <span class="dv col_3">{{ vo.updtTrmlNm }}</span>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX020P130.035') }}</th><!-- 셔틀 사유 -->
                <td>
                  <div class="tbl_form screen-to-pdf-hide">
                    <span class="dv col_6">
                      <input type="text" v-model="formData.reqRsnCont" placeholder="(셔틀 사유)">
                    </span>
                    <span class="dv"></span>
                  </div>
                  <div class="tbl_form screen-to-pdf-show">
                    <span class="dv col_6">{{ formData.reqRsnCont }}</span>
                    <span class="dv"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="normal_box">
            <p>{{ $t('msg.ONEX020P130.036') }}</p>
            <p class="text_center mt10">
              {{ $t('msg.ONEX020P130.037') }}
            </p>
            <p>{{ $t('msg.ONEX020P130.038') }}</p>
            <p class="text_center mt10">{{ $ekmtcCommon.changeDatePattern(nowDate, '.') }}</p>
            <p class="text_center mt10">
              <span class="screen-to-pdf-hide">
                <input
                  v-model="agreeYn"
                  type="checkbox"
                  name="no"
                  id="chk1"
                  true-value="Y"
                  false-value="N"
                >
                <label for="chk1"><span class="offscreen">No</span></label>
              </span>
              <span class="screen-to-pdf-show">
                <input
                  checked="checked"
                  disabled="disabled"
                  type="checkbox"
                  name="no"
                  id="chk1_temp"
                >
                <label for="chk1_temp"><span class="offscreen">No</span></label>
              </span>
              {{ $t('msg.ONEX020P130.039') }} (<span class="ml10 mr10">{{ bookingInfo.actShprCstEnm }}</span>){{ $t('msg.ONEX020P130.040') }}
            </p>
            <p class="txt_desc mt10" style="background: none;">
              <span class="font_medium">[{{ $t('msg.ONEX020P130.041') }}]</span>
              {{ $t('msg.ONEX020P130.042') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX020P130.043') }} : {{ $t('msg.ONEX020P130.044') }}<br>
              <span style="margin-left:34px;">{{ $t('msg.ONEX020P130.045') }}</span> {{ $t('msg.ONEX020P130.046') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX020P130.043') }} : {{ $t('msg.ONEX020P130.047') }}<br>
              <span style="margin-left:34px;">{{ $t('msg.ONEX020P130.048') }}</span> {{ $t('msg.ONEX020P130.049') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX020P130.043') }} : {{ $t('msg.ONEX020P130.050') }}<br>
              <span style="margin-left:34px;">{{ $t('msg.ONEX020P130.051') }}</span> {{ $t('msg.ONEX020P130.052') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX020P130.043') }} : {{ $t('msg.ONEX020P130.053') }}
            </p>
          </div>
        </div><!-- content_box // -->

        <div
          v-if="delegateYn === 'N'"
          class="content_box"
        >
          <!-- content_box -->
          <p v-if="!(memberDetail.userCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'O')">
            {{ $t('msg.ONEX020P130.054') }}
          </p>
          <p class="mt5">
            <span><a class="button sm" href="#" @click.prevent="excelDownload('ContainerShuttleRequest.xlsx')">{{ $t('msg.ONEX020P130.055') }}</a></span><!-- 컨테이너 셔틀 요청서 양식 다운 -->
            <span v-if="!(memberDetail.userCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'O')" class="ml5"><a class="button sm" href="#" @click.prevent="moveDelegateReqPage()">{{ $t('msg.ONEX020P130.056') }}</a></span><!-- 위수임 신청 바로가기 -->
            <span v-if="!(memberDetail.userCtrCd === 'KR' && $store.getters['auth/getCalcEiCatCd'] === 'O')">
              <button type="button" class="tbl_icon help ml10" @click.prevent="guidePop()">{{ $t('msg.ONEX020P130.057') }}</button><!-- 도움말 -->
            </span>
          </p>

          <h2 class="content_title">{{ $t('msg.ONEX020P130.058') }}</h2><!-- 컨테이너 셔틀 요청서 제출 -->
          <table class="tbl_row">
            <colgroup>
              <col style="width:200px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{{ $t('msg.ONEX020P130.059') }}</th><!-- 제출 -->
                <td>
                  <div class="filebox" id="file_att">
                    <a
                      v-if="uploadParam.length === 0"
                      class="button sm gray file"
                      @click.prevent="uploadFilePop()"
                    >
                      {{ $t('msg.ONEX020P130.027') }}
                    </a>
                    <div v-if="uploadParam.length > 0">
                      <span class="tbl_icon attach"></span>
                      <a class="text_link">{{ uploadParam[0].originalName }}</a>
                      <a
                        class="tbl_icon attdel"
                        @click="removeUploadFile()"
                      >X</a>
                    </div>
                  </div>
                </td>
                <td class="border_left_none">
                  <!--<a class="button sm" href="#">제출</a>-->
                </td>
              </tr>
            </tbody>
          </table>
          <p class="txt_desc mt10" style="background: none;">
            <span class="font_medium">[{{ $t('msg.ONEX020P130.041') }}]</span>
            {{ $t('msg.ONEX020P130.042') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX020P130.043') }} : {{ $t('msg.ONEX020P130.044') }}<br>
            <span style="margin-left:34px;">{{ $t('msg.ONEX020P130.045') }}</span> {{ $t('msg.ONEX020P130.046') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX020P130.043') }} : {{ $t('msg.ONEX020P130.047') }}<br>
            <span style="margin-left:34px;">{{ $t('msg.ONEX020P130.048') }}</span> {{ $t('msg.ONEX020P130.049') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX020P130.043') }} : {{ $t('msg.ONEX020P130.050') }}<br>
            <span style="margin-left:34px;">{{ $t('msg.ONEX020P130.051') }}</span> {{ $t('msg.ONEX020P130.052') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX020P130.043') }} : {{ $t('msg.ONEX020P130.053') }}
          </p>
        </div><!-- content_box // -->

        <div class="mt10 text_right screen-to-pdf-hide">
          <a class="button blue lg mr5" @click="reqShuttleSave()">{{ $t('msg.ONEX020P130.059') }}</a>
          <a v-if="delegateYn === 'Y'" class="button gray lg" @click="$emit('close')">{{ $t('msg.ONEX020P130.068') }}</a>
          <a v-else class="button gray lg" @click="confirmClose()">{{ $t('msg.ONEX020P130.068') }}</a>
        </div>

        <win-layer-pop v-if="delegateYn === 'Y'" class="popup_dim">
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="popParams"
            @close="closePopup"
            @selectFunc="selectFunc"
          />
        </win-layer-pop>
        <win-layer-pop v-if="delegateYn === 'N'" ref="uploadPop" class="popup_dim">
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="uploadParam"
            :single-file-yn="true"
            @set-dext-file-info="arrangeUploadParam"
            @close="closePopup"
          />
        </win-layer-pop>
        <div v-show="false" id="guide_pop" v-if="delegateYn === 'N'">
          <component
            :is="customComponent"
            v-if="customComponent"
            @close="closePopup"
          />
        </div>
      </div><!-- popup_cont -->
    </form>
  </div>
</template>

<script>

import commons from '@/api/services/commons'
import docs from '@/api/rest/trans/docs'
import transCommon from '@/api/rest/trans/transCommon'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ContainerGuidePop from '@/pages/trans/popup/ContainerGuidePop'
import DocUploadPop from '@/pages/trans/popup/DocUploadPop'
import { rootComputed } from '@/store/helpers'
import { file } from 'jszip'

export default {
  name: 'ConatainerShuttlePop',
  components: {
    rootComputed,
    WinLayerPop,
    DocUploadPop,
    ContainerGuidePop
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: ''
        }
      }
    }
  },
  data () {
    return {
      delegateYn: '',
      ops: {
        scrollPanel: {
          speed: 300
        },
        bar: {
          showDelay: 500,
          keepShow: true,
          background: '#c1c1c1',
          opacity: 1
        }
      },
      nowDate: '',
      bookingInfo: {
        type: Object,
        default: null
      },
      cntrTypeList: [
        { cdNm: 'Dry', cd: 'GP', rmk: '20/40' },
        { cdNm: 'HIGH CUBE', cd: 'HC', rmk: '40/45' },
        { cdNm: 'REEFER', cd: 'RF', rmk: '20' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH', rmk: '40' },
        { cdNm: 'TANK', cd: 'TK', rmk: '20/40' },
        { cdNm: 'OPEN TOP', cd: 'OT', rmk: '20/40' },
        { cdNm: 'FLAT RACK', cd: 'FR', rmk: '20/40' },
        { cdNm: 'SUPER RACK', cd: 'SR', rmk: '40' }
      ],
      agreeYn: 'N',
      picNmh: '',
      //이전값
      tmpPrevVal: '',
      toolTipActive: false,
      cstInfoList: [],
      cntrList: [],
      selectFunc: null,
      customComponent: null,
      popParams: {},
      uploadParam: [],
      formData: {
        entPicNo: '',
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        reqRsnCont: '',
        //Bl No.에 묶여진 컨테이너 리스트
        cntrNoList: [],
        //실제 보내는 컨테이너 리스트
        containerList: [
          {
            // 컨테이너 넘버
            cntrNo: '',
            // 출발지 터미널 코드
            bfTrmlCd: '',
            bfTrmlNm: '',
            // 도착지 터미널 코드
            updtTrmlCd: '',
            updtTrmlNm: ''
          }
        ],
        uploadFileInfos: []
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      if (this.$ekmtcCommon.isNotEmpty(this.$props.parentInfo.blNo)) {
        this.setDelegateYn(this.$props.parentInfo.blNo)
      }
    }
  },
  created () {
    const parentInfo = this.parentInfo
    if (this.$ekmtcCommon.isNotEmpty(parentInfo.blNo)) {
      this.init()
    } else {
      const TH = this
      this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.014'), function () {
        TH.$emit('close')
      })
    }
  },
  methods: {
    async init () {
      const TH = this
      const parentInfo = TH.parentInfo

      await this.setDelegateYn(parentInfo.blNo)

      // 로그인한 정보를 이용하여 프로필 정보 가져오기
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
        } else {
        const data = this.selectedProfile
        this.formData.entPicNo = data.picNo
        this.formData.reqPicNm = data.picNm
        this.formData.reqPicEmlAddr = data.emlAddr

        let usrTelNo = data.telPlcNo || ''

        if (this.$ekmtcCommon.isNotEmpty(data.telOfcNo)) {
          usrTelNo += '-' + data.telOfcNo
        }

        if (this.$ekmtcCommon.isNotEmpty(data.telNo)) {
          usrTelNo += '-' + data.telNo
        }

        this.formData.reqPicTelNo = usrTelNo
      }

      const arrPromise = [
        commons.getServerTime().then(res => {
          if (res.data !== undefined && res.data !== null) {
            TH.nowDate = res.data.substring(0, 8)
          }
        }),
        commons.getCstInfo().then(res => {
          this.cstInfoList = res
        })
      ]

      arrPromise.push(
        docs.getReqDocBookingBl(parentInfo.blNo).then(async res => {
          const result = res.data
          await TH.setBookingInfo(result)
        })
      )

      await Promise.all(arrPromise)
    },
    // 위수임 여부 판단하기
    async setDelegateYn (blNo) {
      // CD_ID = 01007 (30: 수출 ALL, 39: 컨테이너 셔틀 요청)
      await transCommon.getBlDelegateYn({ blNo: blNo, arrCstGrpCatCd: ['30', '39'] }).then(async (res) => {
        if (res.data === 'Y') {
          this.delegateYn = 'Y'
        } else {
          this.delegateYn = 'N'
        }
      })
    },
    // Booking 정보 가져오기
    async setBookingInfo (result) {
      const TH = this
      this.bookingInfo = result

      if (this.$ekmtcCommon.isNotEmpty(this.bookingInfo.blNo)) {
        const blNo = this.bookingInfo.blNo

        await docs.getBkgContainerInfo(blNo).then(res => {
          const cntrList = res.data

          console.log('###### getBkgContainerInfo ######')
          console.log(cntrList)

          if (cntrList !== undefined && cntrList.length > 0) {
            cntrList.forEach(c => {
              const cdInfo = TH.cntrTypeList.filter(vo => vo.cd === c.cntrTypCd)

              if (cdInfo !== undefined && cdInfo.length > 0) {
                c.cntrTypNm = cdInfo[0].cdNm
              }
            })

            TH.formData.cntrNoList = cntrList

            // ContainerList에 GATE-IN된 컨테이너가 있는지 체크
            let cntrArr = cntrList.map(cntr => {
                return cntr.cntrNo
              }).join(',')

            const data = {
              cntrNo: cntrArr,
              blNo: result.blNo,
              bkgNo: result.bkgNo,
              cntrStsCd: 'GTI'
            }

            // docs.getContainerLogCnt(data).then(res => {
            //   if (res.headers.respcode === 'C0000') {
                if (TH.$ekmtcCommon.isNotEmpty(TH.formData.cntrNoList)) {
                  TH.formData.containerList[0].cntrNo = ''
                }
            //   } else {
            //     // 셔틀 요청 가능한 컨테이너가 없습니다. 담당자에게 문의해주십시오.
            //     TH.$ekmtcCommon.alertCallback(TH.$t('msg.ONEX020P130.075'), () => {
            //       TH.$emit('close')
            //     })
            //   }
            // })
          } else {
            //해당 BL No의 컨테이너가 없습니다.
            TH.$ekmtcCommon.alertCallback(TH.$t('msg.ONEX020P130.071'), () => {
              TH.$emit('close')
            })
          }
        })
      }
    },
    addContainer (flag) {
      const defInfo = {
        cntrNo: '',
        cntrTypCd: '',
        cntrTypNm: '',
        bfTrmlCd: '',
        updtTrmlCd: ''
      }

      const size = this.formData.containerList.length

      if (flag !== 'init') {
        if (this.formData.cntrNoList.length - 1 < size) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P130.069'))
        } else {
          this.formData.containerList.push(defInfo)
        }
      }
    },
    removeContainer (idx) {
      const containerList = this.formData.containerList
      containerList.splice(idx, 1)

      const containerLen = containerList.length
      if (containerLen === 0) {
        this.addContainer()
      }
    },
    // 클릭 시 컨테이너 정보 가져오기
    async setCntrInfo (idx) {
      console.log('####### setCntrInfo #######')
      const result = this.bookingInfo
      const cntrNo = this.formData.containerList[idx].cntrNo

      if (cntrNo !== '' && cntrNo !== undefined) {
        const data = {
          cntrNo: cntrNo,
          blNo: result.blNo,
          bkgNo: result.bkgNo,
          cntrStsCd: 'GTI'
        }

        console.log('####### getCntrInfo #######')
        // 해당 bl No에 묶인 Container의 Terminal 정보 가져오기
        await docs.getContainerLog(data).then(res => {
          const cntrInfoList = res.data

          if (this.$ekmtcCommon.isNotEmpty(cntrInfoList) && cntrInfoList.length > 0) {
            for (let cntrInfo of cntrInfoList) {
              //from, to 자동 매핑
              this.formData.containerList[idx].bfTrmlNm = cntrInfo.plcCd + '/' + cntrInfo.trmlCd
              this.formData.containerList[idx].bfTrmlCd = cntrInfo.trmlCd
              this.formData.containerList[idx].updtTrmlNm = result.polPlcNm + '/' + result.polTrmlCd
              this.formData.containerList[idx].updtTrmlCd = result.polTrmlCd
            }
          } else {
            this.formData.containerList[idx].cntrNo = ''
            this.formData.containerList[idx].bfTrmlCd = ''
            this.formData.containerList[idx].updtTrmlNm = result.polPlcNm + '/' + result.polTrmlCd
            this.formData.containerList[idx].updtTrmlCd = result.polTrmlCd
            this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P130.073')) // 해당 컨테이너는 GATE-IN된 컨테이너가 아닙니다.
          }
        })
      } else {
        this.formData.containerList[idx].cntrNo = ''
        this.formData.containerList[idx].bfTrmlCd = ''
        this.formData.containerList[idx].bfTrmlNm = ''
        this.formData.containerList[idx].updtTrmlCd = ''
        this.formData.containerList[idx].updtTrmlNm = ''
      }
    },
    //모두선택 버튼 클릭시 컨테이너 정보 가져오기
    setCntrInfoAll () {
      console.log('##### setCntrAll #####')
      const result = this.bookingInfo
      const cntrList = this.formData.cntrNoList

      if (cntrList[0] !== undefined && cntrList[0] !== null) {
        //컨테이너 여부에 따라서 로직 들어가기
        this.formData.containerList = []
        this.cntrNoAllList = []

        for (let i = 0; i < this.formData.cntrNoList.length; i++) {
          this.cntrNoAllList.push(this.formData.cntrNoList[i].cntrNo)
        }

        const data = {
          cntrNo: this.cntrNoAllList.join(),
          blNo: result.blNo,
          bkgNo: result.bkgNo,
          cntrStsCd: 'GTI'
        }

        // 모두 선택일 경우 - 해당 bl No에 묶인 모든 Container의 Terminal 정보 가져오기
        docs.getContainerLog(data).then(res => {
          const list = res.data

          if (this.$ekmtcCommon.isNotEmpty(list)) {
            list.forEach((item) => {
            //from, to 자동 매핑
              if (item !== undefined && item !== null) {
                item.bfTrmlNm = item.plcCd + '/' + item.trmlCd
                item.bfTrmlCd = item.trmlCd
                item.updtTrmlNm = result.polPlcNm + '/' + result.polTrmlCd
                item.updtTrmlCd = result.polTrmlCd
              } else {
                item = { cntrNo: '', bftrmlNm: '', bftrmlCd: '', updtTrmlNm: result.polPlcNm + '/' + result.polTrmlCd, updtTrmlCd: result.polTrmlCd }
              }

              this.formData.containerList.push(item)
            })
          } else {
            this.formData.containerList.push({ cntrNo: '', bftrmlNm: '', bftrmlCd: '', updtTrmlNm: result.polPlcNm + '/' + result.polTrmlCd, updtTrmlCd: result.polTrmlCd })
            this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P130.070'))
            return false
          }
        })
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P130.071'))
      }
    },
    //컨테이너 밸리데이션 전체 체크
    checkValidationCntrAll () {
      let isOk = true
      const containerList = this.formData.containerList

      for (let idx = 0; idx < containerList.length; idx++) {
        if (!this.checkValidationCntrNo(idx)) {
          isOk = false
        }
      }

      return isOk
    },
    //컨테이너 밸리데이션 체크
    checkValidationCntrNo (idx) {
      let isOk = true
      const frm = document.querySelector('#frm_req_pop')
      const selector = frm.querySelector('#cntrNo_' + idx)
      const selector1 = frm.querySelector('#fromTerminal_' + idx)
      const selector2 = frm.querySelector('#toTerminal_' + idx)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (this.$ekmtcCommon.isEmpty(selector.value) ||
          this.$ekmtcCommon.isEmpty(selector1.value) ||
          this.$ekmtcCommon.isEmpty(selector2.value)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P130.072')) // 컨테이너를 선택해 주십시오
        isOk = false
      }

      return isOk
    },
    //컨테이너 중복 체크
    async fnChkCntrNo (idx, flag) {
      const containerList = this.formData.containerList
      const cntrNo = containerList[idx].cntrNo
      if (cntrNo.length === 0) return

      for (let i = 0; i < containerList.length; i++) {
        if (i !== idx && this.$ekmtcCommon.isNotEmpty(containerList[i].cntrNo) && containerList[i].cntrNo.trim() === cntrNo.trim()) {
          this.$ekmtcCommon.alertCallback(this.$t('msg.CSBK100.247'), function () {
            containerList[idx].cntrNo = ''
            containerList[idx].bfTrmlNm = ''
            containerList[idx].bfTrmlCd = ''
          })
        }
      }
    },
    async reqShuttleSave () {
      let isOk = true
      const TH = this
      const formData = TH.formData

      if (TH.delegateYn === 'Y') {
        if (!TH.checkValidationCntrAll()) {
          isOk = false
        }
      }

      if (TH.delegateYn === 'N') {
        formData.uploadFileInfos = this.uploadParam.map((item) => {
          if (item.new !== true) {
            item.fileId = item.asFileId
          }
          return item
        })

        TH.$delete(formData, 'containerList')

        if (TH.delegateYn === 'N' && formData.uploadFileInfos.length === 0) {
          const attachSelector = document.querySelector('#file_att')
          TH.$ekmtcCommon.showErrorTooltip(attachSelector, TH.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      if (isOk) {
        if (TH.agreeYn !== 'Y' && TH.delegateYn === 'Y') {
          TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX020P130.026'))
          return
        }

        let successFlag = true

        formData.vslCd = TH.bookingInfo.vslCd
        formData.voyNo = TH.bookingInfo.voyNo
        formData.polCtrCd = TH.bookingInfo.polCtrCd
        formData.polPortCd = TH.bookingInfo.polPortCd
        formData.podCtrCd = TH.bookingInfo.podCtrCd
        formData.podPortCd = TH.bookingInfo.podPortCd
        formData.shprCstNo = TH.bookingInfo.shprCstNo
        formData.shprCstNm = TH.bookingInfo.shprCstNm
        formData.blNo = TH.parentInfo.blNo
        formData.bkgNo = TH.bookingInfo.bkgNo

        //터미널만 가공
        if (TH.agreeYn !== 'Y' && TH.delegateYn === 'Y') {
          formData.containerList.forEach(item => {
            item.bfTrmlCd = item.bfTrmlCd.split('/')[1]
            item.updtTrmlCd = TH.bookingInfo.polTrmlCd
          })
        }

        formData.reqCatCd = '02'

        if (this.delegateYn === 'Y') {
          const frm = document.querySelector('#frm_req_pop')
          const ele = frm.querySelector('.shuttle_cont_area')
          this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

          await this.$ekmtcCommon.screenToPdf({ cssSelector: '.shuttle_cont_area' })
            .then(async (res) => {
              const reqData = new FormData()
              const fileName = this.$t('msg.ONEX020P130.001') + '.pdf'
              reqData.append('files', res.output('blob'), fileName)

              await commons.tempFileUpload(reqData).then((result) => {
                  console.log(result)
                  const fileId = result.data.fileId

                  const fileObj = {
                    fileId: fileId,
                    originalName: fileName
                  }

                  formData.uploadFileInfos.push(fileObj)
                })
                .catch(err => {
                  console.log(err)
                })

                TH.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
            })
        }

        let result = null
        await docs.reqDocSubmit(formData).then(res => {
          if (res.headers.respcode !== 'C0000') {
            successFlag = false
          }
          result = res.data
        }).catch(() => {
          successFlag = false
        }).finally(() => {
          if (successFlag && result !== null) {
            TH.$ekmtcCommon.alertCallback(TH.$t('msg.CSBK100.236'), function () {
              TH.$emit('selectFunc', result)
              TH.$emit('close')
            })
          } else {
            TH.$ekmtcCommon.alertDefault(TH.$t('msg.CSBK100.237'))
          }
        })
      }
    },
    uploadFilePop () {
      const uploadParam = this.uploadParam

      this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop')
    },
    guidePop () {
      this.openPopup('ContainerGuidePop')
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    closePopup (compNm) {
      const elemGuidPopup = document.querySelector('#guide_pop')
      this.customComponent = null
      this.selectFunc = null

      if (this.delegateYn === 'N') {
        if (compNm !== 'confirmClose') {
          elemGuidPopup.style.display = 'none'
        } else {
          this.$emit('close')
        }
      }

      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    arrangeUploadParam () {
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)
      this.uploadParam.map((item) => (item.newFlag = item.new))
    },
    removeUploadFile () {
      this.uploadParam.splice(0, 1)
      this.customComponent = null
    },
    showToolTip () {
      console.log(this.toolTipActive)
      this.toolTipActive = !this.toolTipActive
    },
    async confirmClose () {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX020P130.062'), useConfirmBtn: true })) {
        this.$emit('close')
      }
    },
    async moveDelegateReqPage () {
      const TH = this
      // 위수임 신청 페이지로 이동하시겠습니까?
      if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX060P012.089'), useConfirmBtn: true })) {
        TH.$emit('close')
        TH.$router.push({ name: 'import-business-agency-information-main' })
      }
    },
    isPrevValue (e) {
      const val = e.target.value
      this.tmpPrevVal = val
    },
    async excelDownload (fileName) {
      console.log('#### excel Download ####')

      const data = {
        fileNm: fileName,
        blNo: this.parentInfo.blNo
      }

      if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.blNo)) {
        //using blob download
        const result = await docs.getExcelDownload(data)
        console.log('##### excelDownload #####')
        console.log(result)
        //blob 객체 생성
        const blob = new Blob([result.data], { type: result.headers['content-type'] })

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //IE에서 동작
          window.navigator.msSaveOrOpenBlob(result.data, fileName)
        } else {
          console.log(result.data)
          //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')

          link.href = url
          link.target = '_self'
          link.download = fileName
          link.click()

          //url 사용 후 메모리에서 제거
          window.URL.revokeObjectURL(url)
        }
      }
    }
  }
}

</script>

<style scoped>
.doc_req_pop {width:910px; height: 600px; overflow-y: scroll;}
.doc_req_pop > .layer_close {z-index: 10;}
.div_cntr_area + .div_cntr_area {margin-top: 5px;}
.text_link {color: #000; padding-left: 2px;}
.text_link:hover {text-decoration: underline !important;}
.attdel {cursor: pointer;}
</style>
