var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "popup_wrap doc_req_pop" }, [
    _c(
      "button",
      {
        staticClass: "layer_close",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      },
      [_vm._v("close")]
    ),
    _c(
      "form",
      {
        attrs: { id: "frm_req_pop" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "popup_cont shuttle_cont_area" },
          [
            _c("h1", { staticClass: "page_title" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P130.001")) + " "),
              _c(
                "span",
                { staticClass: "position_relative screen-to-pdf-hide" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon help",
                      on: {
                        mouseover: function ($event) {
                          return _vm.showToolTip()
                        },
                        mouseleave: function ($event) {
                          return _vm.showToolTip()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.057")))]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.toolTipActive,
                          expression: "toolTipActive",
                        },
                      ],
                      staticClass: "tooltip_wrap short position_absolute",
                      staticStyle: { width: "400px", left: "0", top: "28px" },
                    },
                    [
                      _c("div", { staticClass: "cont" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.001"))),
                        ]),
                        _c("ul", { staticClass: "bul_list_sm t2" }, [
                          _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P130.002")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P130.067")) + " "
                            ),
                          ]),
                        ]),
                        _c(
                          "table",
                          {
                            staticClass: "tbl_col mt10",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _vm._m(0),
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { rowspan: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.003"))),
                                ]),
                                _c("th", { attrs: { rowspan: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.004"))),
                                ]),
                                _c("th", { attrs: { colspan: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.005"))),
                                ]),
                              ]),
                              _c("tr", [
                                _c("th", { staticClass: "border_left" }, [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.006"))),
                                ]),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.007"))),
                                ]),
                              ]),
                            ]),
                            _c("tbody", [
                              _c("tr", [
                                _c("td", { attrs: { rowspan: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.008"))),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.009"))),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.010"))
                                  ),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.011"))
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "border_left" }, [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.014"))),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.012"))
                                  ),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.013"))
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { attrs: { rowspan: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.015"))),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.016"))),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.010"))
                                  ),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.011"))
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "border_left" }, [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.017"))),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.012"))
                                  ),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.013"))
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.018"))),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.016"))),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.010"))
                                  ),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.011"))
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.019"))),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.016"))),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.010"))
                                  ),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.011"))
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.020"))),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.016"))),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.010"))
                                  ),
                                ]),
                                _c("td", { staticClass: "text_right" }, [
                                  _vm._v(
                                    "₩" + _vm._s(_vm.$t("msg.ONEX020P130.011"))
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm.delegateYn === "Y"
              ? _c("div", { staticClass: "content_box" }, [
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(1),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.021"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$ekmtcCommon.changeDatePattern(
                                    _vm.nowDate,
                                    "."
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.022"))),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.023"))),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.024"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(
                              " " + _vm._s(_vm.bookingInfo.shprCstNm) + " "
                            ),
                          ]),
                          _c("div", { staticClass: "tbl_form mt5" }, [
                            _c("div", [
                              _c("span", { staticClass: "dv col_1" }, [
                                _vm._v(_vm._s(_vm.formData.reqPicNm)),
                              ]),
                              _c("span", { staticClass: "dv col_2" }, [
                                _vm._v(_vm._s(_vm.formData.reqPicTelNo)),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(_vm._s(_vm.formData.reqPicEmlAddr)),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.025"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(
                              " " + _vm._s(_vm.bookingInfo.actShprCstEnm) + " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.delegateYn === "Y"
              ? _c(
                  "div",
                  { staticClass: "content_box mt10 shuttle_cont_area" },
                  [
                    _c("table", { staticClass: "tbl_row" }, [
                      _vm._m(2),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.028"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _vm._v(" " + _vm._s(_vm.bookingInfo.blNo) + " "),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.029"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.polPlcNm) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.podPlcNm) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_4" }),
                            ]),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.030"))),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.vslNm) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.bookingInfo.voyNo) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_4" }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("table", { staticClass: "tbl_row mt10" }, [
                      _vm._m(3),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("msg.ONEX020P130.033")) +
                                " " +
                                _vm._s(_vm.$t("msg.ONEX020P130.034"))
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("p", { staticClass: "screen-to-pdf-hide" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button blue lg mt10",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.setCntrInfoAll()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.031")))]
                              ),
                            ]),
                          ]),
                          _c(
                            "td",
                            [
                              _vm._l(
                                _vm.formData.containerList,
                                function (vo, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: "cntrList_" + idx,
                                      staticClass:
                                        "tbl_form div_cntr_area screen-to-pdf-hide",
                                      attrs: { id: "alertArea_" + idx },
                                    },
                                    [
                                      _c("span", { staticClass: "dv col_4" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: vo.cntrNo,
                                                expression: "vo.cntrNo",
                                              },
                                            ],
                                            attrs: { id: "cntrNo_" + idx },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.isPrevValue($event)
                                              },
                                              change: [
                                                function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    vo,
                                                    "cntrNo",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                                function ($event) {
                                                  ;[
                                                    _vm.setCntrInfo(idx),
                                                    _vm.fnChkCntrNo(idx),
                                                  ]
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "msg.ONEX020P130.074"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.formData.cntrNoList,
                                              function (option) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: option.cntrNo,
                                                    domProps: {
                                                      value: option.cntrNo,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(option.cntrNo) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _c("span", { staticClass: "dv" }, [
                                        idx === 0
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "tbl_icon plus",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addContainer()
                                                  },
                                                },
                                              },
                                              [_vm._v(" plus ")]
                                            )
                                          : _vm._e(),
                                        idx !== 0
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "tbl_icon minus",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeContainer(
                                                      idx
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" minus ")]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("span", { staticClass: "dv col_3" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: vo.bfTrmlNm,
                                              expression: "vo.bfTrmlNm",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "fromTerminal_" + idx,
                                            placeholder: "From - Terminal",
                                            readonly: "",
                                          },
                                          domProps: { value: vo.bfTrmlNm },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                vo,
                                                "bfTrmlNm",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("span", { staticClass: "dv col_3" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: vo.updtTrmlNm,
                                              expression: "vo.updtTrmlNm",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "toTerminal_" + idx,
                                            placeholder: "To - Terminal",
                                            readonly: "",
                                          },
                                          domProps: { value: vo.updtTrmlNm },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                vo,
                                                "updtTrmlNm",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.formData.containerList,
                                function (vo, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: "cntr_" + idx,
                                      staticClass:
                                        "tbl_form div_cntr_area screen-to-pdf-show",
                                      attrs: { id: "alertArea_" + idx },
                                    },
                                    [
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "dv col_3" },
                                          [_vm._v(_vm._s(vo.cntrNo))]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "dv col_3" },
                                          [_vm._v(_vm._s(vo.bfTrmlNm))]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "dv col_3" },
                                          [_vm._v(_vm._s(vo.updtTrmlNm))]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.035"))),
                          ]),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "tbl_form screen-to-pdf-hide" },
                              [
                                _c("span", { staticClass: "dv col_6" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formData.reqRsnCont,
                                        expression: "formData.reqRsnCont",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      placeholder: "(셔틀 사유)",
                                    },
                                    domProps: {
                                      value: _vm.formData.reqRsnCont,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.formData,
                                          "reqRsnCont",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("span", { staticClass: "dv" }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "tbl_form screen-to-pdf-show" },
                              [
                                _c("span", { staticClass: "dv col_6" }, [
                                  _vm._v(_vm._s(_vm.formData.reqRsnCont)),
                                ]),
                                _c("span", { staticClass: "dv" }),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "normal_box" }, [
                      _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.036")))]),
                      _c("p", { staticClass: "text_center mt10" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX020P130.037")) + " "
                        ),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.038")))]),
                      _c("p", { staticClass: "text_center mt10" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$ekmtcCommon.changeDatePattern(_vm.nowDate, ".")
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "text_center mt10" }, [
                        _c("span", { staticClass: "screen-to-pdf-hide" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.agreeYn,
                                expression: "agreeYn",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              name: "no",
                              id: "chk1",
                              "true-value": "Y",
                              "false-value": "N",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.agreeYn)
                                ? _vm._i(_vm.agreeYn, null) > -1
                                : _vm._q(_vm.agreeYn, "Y"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.agreeYn,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "Y" : "N"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.agreeYn = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.agreeYn = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.agreeYn = $$c
                                }
                              },
                            },
                          }),
                          _vm._m(4),
                        ]),
                        _vm._m(5),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX020P130.039")) + " ("
                        ),
                        _c("span", { staticClass: "ml10 mr10" }, [
                          _vm._v(_vm._s(_vm.bookingInfo.actShprCstEnm)),
                        ]),
                        _vm._v(
                          ")" + _vm._s(_vm.$t("msg.ONEX020P130.040")) + " "
                        ),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "txt_desc mt10",
                          staticStyle: { background: "none" },
                        },
                        [
                          _c("span", { staticClass: "font_medium" }, [
                            _vm._v(
                              "[" + _vm._s(_vm.$t("msg.ONEX020P130.041")) + "]"
                            ),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX020P130.042")) + " "
                          ),
                          _c("span", { staticClass: "txt_bar" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX020P130.043")) +
                              " : " +
                              _vm._s(_vm.$t("msg.ONEX020P130.044"))
                          ),
                          _c("br"),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "34px" } },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.045")))]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX020P130.046")) + " "
                          ),
                          _c("span", { staticClass: "txt_bar" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX020P130.043")) +
                              " : " +
                              _vm._s(_vm.$t("msg.ONEX020P130.047"))
                          ),
                          _c("br"),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "34px" } },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.048")))]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX020P130.049")) + " "
                          ),
                          _c("span", { staticClass: "txt_bar" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX020P130.043")) +
                              " : " +
                              _vm._s(_vm.$t("msg.ONEX020P130.050"))
                          ),
                          _c("br"),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "34px" } },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.051")))]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX020P130.052")) + " "
                          ),
                          _c("span", { staticClass: "txt_bar" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("msg.ONEX020P130.043")) +
                              " : " +
                              _vm._s(_vm.$t("msg.ONEX020P130.053")) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.delegateYn === "N"
              ? _c("div", { staticClass: "content_box" }, [
                  !(
                    _vm.memberDetail.userCtrCd === "KR" &&
                    _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                  )
                    ? _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX020P130.054")) + " "
                        ),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "mt5" }, [
                    _c("span", [
                      _c(
                        "a",
                        {
                          staticClass: "button sm",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.excelDownload(
                                "ContainerShuttleRequest.xlsx"
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.055")))]
                      ),
                    ]),
                    !(
                      _vm.memberDetail.userCtrCd === "KR" &&
                      _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                    )
                      ? _c("span", { staticClass: "ml5" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button sm",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.moveDelegateReqPage()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.056")))]
                          ),
                        ])
                      : _vm._e(),
                    !(
                      _vm.memberDetail.userCtrCd === "KR" &&
                      _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                    )
                      ? _c("span", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon help ml10",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.guidePop()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.057")))]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("h2", { staticClass: "content_title" }, [
                    _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.058"))),
                  ]),
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(6),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.059"))),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "filebox",
                              attrs: { id: "file_att" },
                            },
                            [
                              _vm.uploadParam.length === 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "button sm gray file",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.uploadFilePop()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("msg.ONEX020P130.027")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.uploadParam.length > 0
                                ? _c("div", [
                                    _c("span", {
                                      staticClass: "tbl_icon attach",
                                    }),
                                    _c("a", { staticClass: "text_link" }, [
                                      _vm._v(
                                        _vm._s(_vm.uploadParam[0].originalName)
                                      ),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "tbl_icon attdel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeUploadFile()
                                          },
                                        },
                                      },
                                      [_vm._v("X")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("td", { staticClass: "border_left_none" }),
                      ]),
                    ]),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "txt_desc mt10",
                      staticStyle: { background: "none" },
                    },
                    [
                      _c("span", { staticClass: "font_medium" }, [
                        _vm._v(
                          "[" + _vm._s(_vm.$t("msg.ONEX020P130.041")) + "]"
                        ),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P130.042")) + " "),
                      _c("span", { staticClass: "txt_bar" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("msg.ONEX020P130.043")) +
                          " : " +
                          _vm._s(_vm.$t("msg.ONEX020P130.044"))
                      ),
                      _c("br"),
                      _c("span", { staticStyle: { "margin-left": "34px" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.045"))),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P130.046")) + " "),
                      _c("span", { staticClass: "txt_bar" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("msg.ONEX020P130.043")) +
                          " : " +
                          _vm._s(_vm.$t("msg.ONEX020P130.047"))
                      ),
                      _c("br"),
                      _c("span", { staticStyle: { "margin-left": "34px" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.048"))),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P130.049")) + " "),
                      _c("span", { staticClass: "txt_bar" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("msg.ONEX020P130.043")) +
                          " : " +
                          _vm._s(_vm.$t("msg.ONEX020P130.050"))
                      ),
                      _c("br"),
                      _c("span", { staticStyle: { "margin-left": "34px" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX020P130.051"))),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P130.052")) + " "),
                      _c("span", { staticClass: "txt_bar" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("msg.ONEX020P130.043")) +
                          " : " +
                          _vm._s(_vm.$t("msg.ONEX020P130.053")) +
                          " "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "mt10 text_right screen-to-pdf-hide" }, [
              _c(
                "a",
                {
                  staticClass: "button blue lg mr5",
                  on: {
                    click: function ($event) {
                      return _vm.reqShuttleSave()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.059")))]
              ),
              _vm.delegateYn === "Y"
                ? _c(
                    "a",
                    {
                      staticClass: "button gray lg",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.068")))]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "button gray lg",
                      on: {
                        click: function ($event) {
                          return _vm.confirmClose()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.068")))]
                  ),
            ]),
            _vm.delegateYn === "Y"
              ? _c(
                  "win-layer-pop",
                  { staticClass: "popup_dim" },
                  [
                    _vm.customComponent
                      ? _c(_vm.customComponent, {
                          tag: "component",
                          attrs: { "parent-info": _vm.popParams },
                          on: {
                            close: _vm.closePopup,
                            selectFunc: _vm.selectFunc,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.delegateYn === "N"
              ? _c(
                  "win-layer-pop",
                  { ref: "uploadPop", staticClass: "popup_dim" },
                  [
                    _vm.customComponent
                      ? _c(_vm.customComponent, {
                          tag: "component",
                          attrs: {
                            "parent-info": _vm.uploadParam,
                            "single-file-yn": true,
                          },
                          on: {
                            "set-dext-file-info": _vm.arrangeUploadParam,
                            close: _vm.closePopup,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.delegateYn === "N"
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    attrs: { id: "guide_pop" },
                  },
                  [
                    _vm.customComponent
                      ? _c(_vm.customComponent, {
                          tag: "component",
                          on: { close: _vm.closePopup },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk1" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "screen-to-pdf-show" }, [
      _c("input", {
        attrs: {
          checked: "checked",
          disabled: "disabled",
          type: "checkbox",
          name: "no",
          id: "chk1_temp",
        },
      }),
      _c("label", { attrs: { for: "chk1_temp" } }, [
        _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }